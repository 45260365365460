import React from 'react';
import './FooterStyle.css';

const Footer = () => {
  return (
    <footer className="footer-containerDevFtr">
      <div className="footer-columnDevFtr">
    
        <img src="https://i.ibb.co/VBwNdzZ/Devon-logo-2-1-Photoroom.png"   alt="Logo" className="footer-logoDevFtr" />
        <p className="footer-textDevFtr">
          Devon Technologies the best talent in the company, we offer you genuine affordable services with unconditional customer-centric approach.
        </p>
      </div>

      <div className="footer-columnDevFtr">
        <h3 className="footer-headingDevFtr">Services</h3>
        <ul className="footer-services-listDevFtr">
         
        <li> <a href='/Development' style={{color:"white"}}>★ Software Development</a></li>
        <li> <a href='/Consulting'style={{color:"white"}}>★ Consulting & Outsourcing</a></li>
        
         
        </ul>
      </div>

      <div className="footer-columnDevFtr">
        <h3 className="footer-headingDevFtr">Technologies</h3>
        <ul className="footer-tech-listDevFtr">
       <li> <a className='options25' href="/Saptrans">★ Sap</a> </li>
       <li> <a className='options25' href="/Cloud">★ Cloud</a> </li>
       <li> <a className='options25' href="/Java">★ Java</a> </li>
       <li> <a className='options25' href="/Testing">★ Testing</a> </li>
       <li> <a className='options25' href="/Fullstack">★ Full-Stack</a> </li>
          {/* <li>★ Application Development</li> */}
          {/* <li>Full Stack</li> */}
        </ul>
      </div>

      <div className="footer-columnDevFtr">
      <img src="https://i.ibb.co/NV8zc2x/Devon-1-Photoroom.png" alt="Logo" className="footer-logoDevFtr12" />
        <h3 className="footer-headingDevFtr" style={{marginTop:"-52px"}}>Address</h3>
        <p className="footer-addressDevFtr">
          <span className="footer-location-iconDevFtr">📍</span> 
          Correspondece: #3rd floor, Lumbini Society, Survey No. 55, Plot No. 108,
            NYN Arcade off Hitech City Main Road, next to Euro School, Gachibowli, Hyderabad, Telangana 500032.
        </p>
        <p className="footer-addressDevFtr">
        Communication: 5th floor, Vasista Bhavan, DLF Cyber City, to, opp. lane, APHB Colony,
         Indira Nagar, Gachibowli, Hyderabad, Telangana 500032
         </p>

        <p className="footer-emailDevFtr">
          <span className="footer-message-iconDevFtr">✉️</span> 
          hr@devontech.in
        </p>

        <p className="footer-emailDevFtr">
          <span className="footer-call-iconDevFtr">📞</span>
          9603547276
        </p>

      </div>
    </footer>
  );
};

export default Footer;
